.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.speechBubble {
  width: 80%;
  border: 3px solid #282c34;
  border-radius: 45px;
  margin-top: 0px;
  margin-left: 15px;
  padding: 20px;
  text-align: center;
  font-family: arial;
  position: relative;
  background-color: #282c34;
  color: silver;
}
.speechBubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 10px solid #282c34;
  border-left: 10px solid transparent;
  border-top: 10px solid #282c34;
  border-bottom: 10px solid transparent;
  left: -20px;
  top: 30px;
}

.nav-drawer .MuiButton-outlined:hover {
  background-color: rgb(87, 132, 177);
  color: white;
}

.compactPagination .MuiTablePagination-displayedRows, .compactPagination .MuiTableCell-footer, .compactPagination .MuiIconButton-sizeMedium{
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top:0px;
  margin-bottom:0px;
}

.MuiDrawer-paper {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

#qualifications table tbody tr:nth-child(even){
  background-color: silver;
}

#qualifications table thead th {
  background-color: black;
  color: white !important;
}

.App-header,
.site-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.site-header .headline {
  font-size: calc(10px + 5vmin);
}

.site-header .tagline,
.jobSummary h3 {
  font-size: calc(10px + 1vmin);
}

.jobSummary {
  padding-left: 2px;
}

.titledSection {
  text-align: left;
}

h2.sectionTitle {
  padding: 0px;
}

pre {
  white-space: pre-wrap;
  word-break: keep-all;
}

@media screen and (max-width: 899px){
  .MuiChip-label {
    white-space: normal !important;
  }
  .MuiChip-root {
    height: auto !important;
    padding: 2px !important;
  }
}


.employment ol.jobs {
  list-style-type: none;
  padding-left: 0px;
}

.employment ol.jobs li {
  margin: 10px 0px;
  padding: 0px;
}

.square {
  aspect-ratio: 1/1;
}

.knowledge .MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}

.eventIcon,
.eventAvatar {
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jobDetailsTable td {
  vertical-align: top;
}

.employment ol.jobs li h3,
.employment ol.jobs li p {
  margin: 0px;
}

.employment ol.jobs li .jobSummary {
  margin-left: 10px;
}

.jobSummary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.employment ol.jobs h3.jobTitle {
  vertical-align: middle;
}

.timelineSummary h3.title, .timelineSummary p.details{
  margin: 0px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  min-height: 100vh;
}